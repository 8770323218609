@mixin textH2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #FFFFFF;
  margin-top: 20px;
}
@mixin textP {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0;
  color: #FFFFFF;
}

@mixin maxWidth {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (max-width: 768.99px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@mixin input {
  background-color: #fff;
  max-width: 450px;
  height: 80px;
  border-radius: 15px;
  width: 100%;
  border: 0;
  padding: 20px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #000000;

  @media screen and (max-width: 375.99px) {
    height: 65px;
  }
}
