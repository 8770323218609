.rtl {
  .main-home {
    .section-physician {
      .section-container {
        .physician-image {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .section-contact {
      .contact-container {
        .contact-message {
          .contact-form {
            .form-group-top {
              .form-group:first-child {
                margin-right: 0 !important;
                margin-left: 15px;
              }
            }
          }
        }

        .contact-info .contact-info-address ul li .contact {
          padding-left: 0;
          padding-right: 15px;
          direction: ltr;
        }
      }
    }

    .physician-text {
      padding-right: 20px;
    }

    .physician-text-mission {
      ul {
        list-style: none !important;

        li {
          p {
            margin-top: 10px;
          }
        }
      }
    }

    .section-container {
      .definition-text {
        .definition-text-cannula {
          h2 {
            font-size: calc(4rem - 1.5vw);
            font-weight: 400;
            font-style: normal;
            padding-bottom: 30px;
            text-align: right !important;
          }

          p {
            font-size: calc(2.75rem - 1.5vw);
            font-weight: 500;
            line-height: 1.5;
            text-align: right !important;
          }
        }

        .definition-btn {
          .definition-contact {
            margin-right: 20px !important;
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

.section-definition {
  .section-container {
    .definition-text-cannula {
      h1 {
        color: #ffffff;
        font-size: 50px;
        font-family: 'Cairo', sans-serif;
        font-weight: 700;
      }

      p {
        color: #ffffff;
        font-size: 20px;
        font-family: 'Cairo', sans-serif;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.main-home {
  .section-definition {
    @media screen and (min-width: 1728px) {
      .section-container {
        .definition-text-cannula {
          h2 {
            font-size: calc(4.5rem - 1.5vw) !important;
          }

          p {
            font-size: calc(3rem - 1.5vw) !important;
          }
        }
      }
    }

    @media screen and (max-width: 1225.99px) {
      .section-container {
        .definition-text {
          padding-bottom: 0 !important;
        }

        .definition-text-cannula {
          h2 {
            font-size: calc(3.5rem - 1.5vw) !important;
            padding-bottom: 20px !important;
          }

          p {
            font-size: calc(2.3rem - 1.5vw) !important;
            padding-bottom: 0px !important;
          }
        }
      }
    }

    @media screen and (max-width: 990.99px) {
      .section-container {
        .definition-text {
          padding-bottom: 0 !important;
        }

        .definition-text-cannula {
          h2 {
            font-size: calc(2.3rem - 1.5vw) !important;
            padding-bottom: 0px !important;
          }

          p {
            font-size: calc(2.1rem - 1.5vw) !important;
            padding-bottom: 0px !important;
          }
        }
      }
    }

    @media screen and (max-width: 834.99px) {
      .section-container {
        .definition-text-cannula {
          margin-top: 90px;

          h2 {
            font-size: calc(2.1rem - 1.5vw) !important;
            padding-bottom: 10px !important;
          }

          p {
            font-size: calc(1.8rem - 1.5vw) !important;
          }
        }

        .definition-btn {
          margin-top: 0px;
          align-items: center;
          justify-content: center;

          .definition-contact {
            margin-right: 20px;

            .btn-contact {
              padding: 15px 25px !important;
            }
          }

          .definition-download {
            .btn-download {
              padding: 15px 20px !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: 799.99px) {
      .section-container {
        .definition-text-cannula {
          margin-top: 0 !important;

          h1 {
            font-size: calc(3rem - 1.5vw) !important;
            text-align: center !important;
          }

          p {
            text-align: center !important;
          }
        }
      }
    }

    @media screen and (max-width: 624.99px) {
      .section-container {
        //.definition-text {
        //  max-width: 600px !important;
        //  padding-bottom: 70px !important;

        .definition-text-cannula {
          margin-top: 0px !important;
          max-width: 100% !important;

          h2 {
            font-size: calc(2.3rem - 2.2vw) !important;
          }

          p {
            font-size: calc(1.6rem - 1.5vw) !important;
          }
        }

        .definition-btn {
          margin-top: 25px !important;

          .definition-contact {
            .btn-contact {
              // padding: 15px 45px !important;
            }
          }
        }

        //}
      }
    }

    @media screen and (max-width: 572.99px) {
      .section-definition {
        max-width: 100%;

        .section-container {
          max-width: 100%;

          .definition-text-cannula {
            margin-top: 0 !important;

            h2 {
              font-size: calc(2.3rem - 2.2vw) !important;
            }

            p {
              font-size: calc(1.6rem - 1.5vw) !important;
            }
          }

          .definition-btn {
            margin-top: 25px !important;

            .definition-contact {
              .btn-contact {
                // padding: 15px 45px !important;
              }
            }

            //}
          }
        }
      }
    }

    @media screen and (max-width: 516.99px) {
      .section-definition {
        max-width: 100%;

        .section-container {
          max-width: 100%;

          .definition-text-cannula {
            margin-top: 0 !important;

            h2 {
              font-size: calc(2.3rem - 2.29vw) !important;
            }

            p {
              font-size: calc(1.6rem - 1.5vw) !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: 428.99px) {
      .section-container {
        .definition-text-cannula {
          margin-top: 0 !important;

          h1 {
            font-size: calc(2.3rem - 2.29vw) !important;
          }

          p {
            font-size: calc(1.6rem - 1.5vw) !important;
          }

          img {
            width: 100px;
            height: 50px;
          }
        }
      }
    }

    @media screen and (max-width: 360.99px) {
      .section-container {
        .definition-text-cannula {
          margin-top: 3px !important;

          h1 {
            font-size: calc(1.9rem - 3vw) !important;
            width: 100%;
          }

          p {
            font-size: calc(1.2rem - 1.5vw) !important;
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 320.99px) {
      .section-container {
        .definition-text-cannula {
          margin-top: 3px !important;

          h1 {
            font-size: calc(1.9rem - 3vw) !important;
            width: 100%;
          }

          p {
            font-size: calc(1.2rem - 1.5vw) !important;
            width: 100%;
          }
        }
      }
    }

    .section-container {
      display: grid;
      //grid-template-columns: 45% 55%;
      align-items: center;
      //padding: 0 20px;

      .definition-text {
        text-align: center;
        padding-bottom: 70px;
        max-width: 531px;
        margin: auto;

        .definition-text-cannula {
          h2 {
            font-size: calc(4rem - 1.5vw);
            font-weight: 400;
            font-style: normal;
            padding-bottom: 30px;
            text-align: left;
          }

          p {
            font-size: calc(2.75rem - 1.5vw);
            font-weight: 500;
            line-height: 1.5;
            text-align: left;
          }
        }

        .definition-btn {
          display: flex;
          margin-top: 50px;

          .definition-contact {
            margin-left: 20px;

            .btn-contact {
              background-color: #fff;
              border: 0;
              color: #000;
              font-size: 1.1rem;
              font-weight: 500;
              padding: 15px 45px;
              border-radius: 10px;
            }
          }

          .definition-download {
            .btn-download {
              background-color: #ff7033;
              border: 0;
              color: #fff;
              font-size: 1.1rem;
              font-weight: 500;
              padding: 15px 45px;
              border-radius: 10px;
            }
          }
        }
      }

      .definition-image {
        margin-top: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  // physician

  .section-physician {
    //@include maxWidth;
    padding-top: 20px;
    padding-bottom: 30px;
    background-image: url(../../assets/home.png);
    background-size: cover;

    .section-container {
      max-width: 1360px;
      display: flex;
      align-content: center;
      align-items: flex-start;
      padding: 0 20px;

      .physician-image {
        padding-right: 40px;

        img {
          max-width: 36.5vw;
          min-height: 36.5vh;
        }
      }

      .physician-text {
        .physician-text-story {
          h2 {
            @include textH2;
            margin-top: 0;
          }

          p {
            @include textP;
          }
        }

        .physician-text-mission {
          h2 {
            @include textH2;
          }

          ul {
            list-style: disc;
            padding-left: 20px;

            li {
              p {
                @include textP;
              }
            }
          }
        }

        .physician-text-vision {
          h2 {
            @include textH2;
          }

          p {
            @include textP;
          }
        }
      }

      @media screen and (max-width: 1279.99px) {
        .physician-image {
          img {
            max-width: 41.5vw;
            min-height: 36.5vw;
          }
        }

        .physician-text-story {
          h2 {
            // margin-bottom: 0;
          }

          p {
            margin-top: 0;
          }
        }

        .physician-text-mission {
          h2 {
            margin-top: 10px;
          }

          ul {
            li {
              p {
                margin-top: 10px;
              }
            }
          }
        }

        .physician-text-vision {
          h2 {
            margin-top: 15px;
          }

          p {
            margin: 0;
          }
        }
      }

      @media screen and (max-width: 999.99px) {
        .physician-image {
          padding-right: 25px;

          img {
            max-width: 47vw;
          }
        }

        .physician-text-story {
          h2 {
            font-size: calc(2.8rem - 1.5vw) !important;
          }

          p {
            font-size: calc(2rem - 1.5vw) !important;
          }
        }

        .physician-text-mission {
          h2 {
            font-size: calc(2.8rem - 1.5vw) !important;
          }

          ul {
            li {
              p {
                font-size: calc(2rem - 1.5vw) !important;
              }
            }
          }
        }

        .physician-text-vision {
          h2 {
            font-size: calc(2.8rem - 1.5vw) !important;
          }

          p {
            font-size: calc(2rem - 1.5vw) !important;
          }
        }
      }

      @media screen and (max-width: 884.99px) {
        .physician-image {
          padding-right: 25px;

          img {
            max-width: 48vw;
          }
        }

        .physician-text-story {
          h2 {
            font-size: calc(2.5rem - 1.5vw) !important;
          }

          p {
            font-size: calc(1.7rem - 1.5vw) !important;
          }
        }

        .physician-text-mission {
          h2 {
            font-size: calc(2.5rem - 1.5vw) !important;
          }

          ul {
            li {
              p {
                font-size: calc(1.7rem - 1.5vw) !important;
              }
            }
          }
        }

        .physician-text-vision {
          h2 {
            font-size: calc(2.5rem - 1.5vw) !important;
          }

          p {
            font-size: calc(1.7rem - 1.5vw) !important;
          }
        }
      }

      @media screen and (max-width: 884.99px) {
        .physician-image {
          padding-right: 20px;

          img {
            max-width: 48vw;
          }
        }

        .physician-text-story {
          h2 {
            font-size: calc(2rem - 1.5vw) !important;
          }

          p {
            font-size: calc(1.5rem - 1.5vw) !important;
          }
        }

        .physician-text-mission {
          h2 {
            font-size: calc(2rem - 1.5vw) !important;
          }

          ul {
            li {
              p {
                font-size: calc(1.5rem - 1.5vw) !important;
              }
            }
          }
        }

        .physician-text-vision {
          h2 {
            font-size: calc(2rem - 1.5vw) !important;
          }

          p {
            font-size: calc(1.5rem - 1.5vw) !important;
          }
        }
      }

      @media screen and (max-width: 428.99px) {
        .physician-image {
          padding-right: 0;

          img {
            max-width: 78vw;
          }
        }

        .physician-text {
          margin-top: 10px;
        }

        .physician-text-story {
          h2 {
            font-size: calc(1.7rem - 1.5vw) !important;
          }

          p {
            font-size: calc(1.2rem - 1.5vw) !important;
          }
        }

        .physician-text-mission {
          h2 {
            font-size: calc(1.7rem - 1.5vw) !important;
          }

          ul {
            li {
              p {
                font-size: calc(1.2rem - 1.5vw) !important;
              }
            }
          }
        }

        .physician-text-vision {
          h2 {
            font-size: calc(1.7rem - 1.5vw) !important;
          }

          p {
            font-size: calc(1.2rem - 1.5vw) !important;
          }
        }
      }
    }

    @media screen and (max-width: 428.99px) {
      .section-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
      }
    }
  }

  // section-banner
  .section-banner {
    background-color: #f5f5f5;
    overflow: hidden;

    .banner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      align-content: center;
      overflow: hidden;
      white-space: nowrap;

      .banner-track {
        display: flex;
        transition: transform 1s ease-out;
      }
      .banner-sponsor {
        min-width: 25%; // Adjust based on visibleImagesCount
        height: 200px;
        margin: 0 10px; // Add margins around the images
      
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      
        @media screen and (max-width: 999.99px) {
          min-width: 50%; // Adjust for smaller screens
          img {
            width: 100%;
            height: 100%;
          }
        }
        @media screen and (max-width: 768.99px) {
          min-width: 50%; // Adjust for smaller screens
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      

    }
  }

  // contact
  .section-contact {
    @include maxWidth;
    margin-bottom: 0.5rem;
    background: #00baf9;
    padding-bottom: 20px;
    padding-top: 20px;

    .contact-container {
      display: grid;
      grid-template-columns: 45% 55%;
      max-width: 1360px;

      .contact-info {
        padding-right: 20px;

        .contact-info-contact {
          h2 {
            @include textH2;
          }

          p {
            @include textP;
          }
        }

        .contact-info-address {
          padding-top: 3rem;

          ul {
            li {
              display: flex;
              align-items: center;
              margin-bottom: 40px;

              .icon {
                background-color: #fff;
                border-radius: 50px;
                width: 42px;
                height: 42px;
                text-align: center;
                position: relative;

                svg {
                  color: #00baf9 !important;
                  position: absolute;
                  left: 11.5px;
                  top: 11.5px;
                  font-weight: 100;
                }
              }

              .contact {
                padding-left: 15px;

                span {
                  font-size: 1rem;
                  color: #6b6b6b;
                  font-weight: 500;
                  font-style: normal;
                }

                h4 {
                  margin-top: 5px;
                  margin-bottom: 0;
                  font-size: 1.2rem;
                  color: #ffffff;
                  font-weight: 500;
                  font-style: normal;
                }
              }
            }
          }
        }

        .contact-info-find {
          margin-top: 4rem;

          .title {
            font-size: 1.3rem;
            color: #ffffff;
            font-weight: 400;
            font-style: normal;
            margin-bottom: 15px;
          }

          .social-media-icon {
            display: flex;
            align-items: center;

            .icon {
              background-color: #fff;
              border-radius: 50px;
              width: 35px;
              height: 35px;
              text-align: center;
              position: relative;
              margin-right: 15px;

              svg {
                color: #00baf9 !important;
                position: absolute;
                left: 8.5px;
                top: 7.5px;
                font-weight: 100;
              }
            }
          }
        }
      }

      .contact-message {
        .contact-message-title {
          @include textH2;
        }

        .contact-form {
          margin-top: 2.5rem;

          .form-group-top {
            display: flex;

            .form-group {
              max-width: 450px;
              width: 100%;

              input {
                @include input;

                &:focus-visible {
                  outline: 0;
                }
              }

              &:first-child {
                margin-right: 25px;
              }
            }
          }

          .form-group-button {
            margin-top: 20px;

            .form-group {
              max-width: 750px;

              input {
                @include input;
                max-width: 750px;

                &:focus-visible {
                  outline: 0;
                }
              }

              textarea {
                margin-top: 20px;
                @include input;
                max-width: 750px;
                min-height: 170px;
                height: 100%;

                &:focus-visible {
                  outline: 0;
                }
              }
            }
          }

          .form-btn {
            margin-top: 20px;

            .btn-submit {
              background-color: #ff6421;
              border: 0;
              border-radius: 10px;
              color: #fff;
              max-width: 180px;
              font-size: 1.6rem;
              font-weight: 500;
              width: 100%;
              padding: 17px 0;
              cursor: pointer;
            }
          }

          .errorMsg {
            color: #f54646;
            font-size: 0.8rem;
            font-weight: 500;
            margin-bottom: 0;
          }

          .error {
            border: 1px solid #f54646 !important;
          }

          .successMsg {
            color: #00f936;
            font-size: 0.8rem;
            font-weight: 500;
            margin-bottom: 0;
          }
        }
      }
    }

    @media screen and (max-width: 799.99px) {
      .contact-container {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;

        .contact-info {
          grid-column: 1 / span 2;
          grid-row: 1;

          .contact-info-find {
            margin-top: 2rem;
          }
        }

        .contact-message {
          grid-column: 1 / span 2;
          text-align: center;
          grid-row: 2;
        }
      }
    }

    @media screen and (max-width: 428.99px) {
      .contact-container {
        .contact-info {
          .contact-info-contact {
            h2 {
              font-size: 2rem;
            }

            p {
              font-size: 1rem;
            }
          }

          .contact-info-address {
            padding-left: 1rem;
            padding-top: 2rem;

            ul {
              li {
                margin-bottom: 20px;

                .contact {
                  span {
                    font-size: 0.9rem;
                  }

                  h4 {
                    font-size: 1.1rem;
                  }
                }
              }
            }
          }

          .contact-info-find {
            margin-top: 0;
          }
        }

        .contact-message {
          .contact-message-title {
            font-size: 2rem;
          }

          .contact-form {
            .form-group-top {
              .form-group:first-child {
                margin-right: 10px !important;
              }
            }
          }

          .form-btn {
            .btn-submit {
              max-width: 140px !important;
              font-size: 1.2rem !important;
              padding: 15px 0 !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: 375.99px) {
      .contact-container {
        .contact-info {
          .contact-info-contact {
            h2 {
              font-size: 1.7rem;
            }

            p {
              font-size: 0.8rem;
            }
          }

          .contact-info-address {
            padding-left: 0;
            padding-top: 2rem;

            ul {
              padding-left: 1rem;

              li {
                .contact {
                  span {
                    font-size: 0.8rem;
                  }

                  h4 {
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }

          .contact-info-find {
            margin-top: 0;
          }
        }

        .contact-message {
          .contact-message-title {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}

.footer {
  background-color: #fff;
  padding: 13px;
  text-align: center;

  p {
    color: #ff6421;
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0;
  }
}

.comp-item {
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 428.99px) {
    .comp-background {
      height: 550px !important;
    }
  }

  @media screen and (max-width: 320.99px) {
    .comp-background {
      height: 350px !important;
    }
  }

  .comp-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}