.rtl {
  .header {
    .header-content {
      .header-content-nav {
        ul {
          li {
            a {
              color: #000;
              font-size: 1.3rem;
              font-weight: 400;
              text-decoration: none;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
    .header-content-nav-mobile {
      padding-right: 30px;
      padding-top: 20px;
      height: 295px !important;
    }
  }
}

.header {
  background: #fff;
  position: relative;

  .header-content-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 30px;
  }

  .header-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  img {
    width: 75px;
    height: 75px;
  }

  .header-content-logo-text {
    padding-left: 15px;
    padding-bottom: 10px;

    h3 {
      color: #000;
      font-size: 2rem;
      font-weight: 400;
      margin: 10px;
    }
  }

  .header-content-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .header-content-btn {
    .btn {
      background-color: transparent;
      border: 0;
      cursor: pointer;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  @media screen and (max-width: 414.99px) {
    .header-content-nav-mobile {
      top: 41px !important;
    }
  }

  .header-content-nav-mobile {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 63px;
    bottom: 0;
    width: 100%;
    height: 270px;
    z-index: 1000;
    padding-left: 30px;
    padding-top: 20px;
    display: none;

    &.active {
      display: block;
    }

    ul {
      text-align: start;
      li {
        padding-bottom: 10px;
      }
    }
  }

  .header-content-nav {
    ul {
      display: flex;
      align-items: center;
    }
  }
  li {
    padding-left: 25px;

    a {
      color: #000;
      font-size: 1.3rem;
      font-weight: 300;
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #00baf9;
      }
    }
    select {
      padding: 1px 12px;
      border: 1px solid #00baf9;
      border-radius: 5px;
      background-color: #fff;
      color: #00baf9;
      font-size: 0.9rem;
      margin: 0;
      font-size: inherit;
      cursor: pointer;
      line-height: inherit;

      &:focus-visible {
        outline: none;
      }
    }
  }
}

@media screen and (max-width: 768.99px) {
  .header {
    img {
      width: 65px !important;
      height: 65px !important;
    }

    .header-content-logo-text {
      h3 {
        font-size: 1.7rem !important;
        margin: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 424.99px) {
  .header {
    img {
      width: 45px !important;
      height: 45px !important;
    }

    .header-content-logo-text {
      h3 {
        font-size: 1.2rem !important;
        margin: 10px !important;
      }
    }

    .header-content-nav-mobile {
      top: 60px;
      height: 238px;
      padding-left: 20px;
      padding-top: 20px;
    }
  }
}
