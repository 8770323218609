*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #00baf9;
  max-width: 100vw;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
}

.rtl {
  direction: rtl;
  font-family: 'Cairo', sans-serif;}

.ltr {
  direction: ltr;
  font-family: 'Cairo', sans-serif;}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
